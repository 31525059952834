<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">抢位任务列表</h5>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
          <a-button type="primary" icon="plus" size="small" @click="addapi()">
            创建抢位任务
          </a-button>
        </a-col>
      </a-row>
    </template>
    <a-table :columns="columns" :data-source="tableData" :pagination="{pageSize: 10,}">

      <template slot="exchange" slot-scope="exchange">
        <div class="table-avatar-info">
          <a-avatar shape="square" :src="exchange" style="margin-right: 0;"/>
        </div>
      </template>

      <template slot="nickname" slot-scope="nickname">
        {{ nickname }}
      </template>

      <template slot="nickname" slot-scope="uniqueName">
        {{ uniqueName }}
      </template>

      <template slot="follow_type" slot-scope="follow_type">
        {{ follow_type }}
      </template>

      <template slot="status" slot-scope="status">
        <a-tag
            class="tag-status"
            :class="status === 1 ? 'ant-tag-success' : 'ant-tag-muted'"
        >
          {{ status === 1 ? "进行中" : "结束" }}
        </a-tag>
      </template>

      <template slot="info" slot-scope="info">
        {{ info }}
      </template>

      <template slot="stopBtn" slot-scope="row">
        <a-button type="link" :data-id="row.id" class="btn-edit" @click="stop(row.id, row.status.value)">
                  {{ row.status === 1 ? "停止抢位" : "" }}
        </a-button>
      </template>

    </a-table>

  </a-card>
</template>


<script>
import http from "../utils/http"

const key = 'updatable';

const columns = [
  {
    title: '交易所',
    dataIndex: 'exchange',
    scopedSlots: {customRender: 'exchange'},
  },
  {
    title: '带单项目',
    dataIndex: 'nickname',
    scopedSlots: {customRender: 'nickname'},
  },
  {
    title: '项目编号',
    dataIndex: 'uniqueName',
    scopedSlots: {customRender: 'uniqueName'},
  },
  {
    title: '跟单类型',
    dataIndex: 'follow_type',
    scopedSlots: {customRender: 'follow_type'},
  },
  {
    title: '创建时间',
    dataIndex: 'create_datetime',
    class: 'text-muted',
  },
  {
    title: '抢位状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
  },
  {
    title: '抢位信息',
    dataIndex: 'info',
    scopedSlots: {customRender: 'info'},
  },
  {
    title: '',
    scopedSlots: {customRender: 'stopBtn'},
    width: 50,
  },
];

const tableData = [
  {
    id: 1,
    exchange: 'images/binance.png',
    nickname: 'test',
    uniqueName: '1234567',
    follow_type: '定比跟单',
    status: 1,
    create_datetime: '2023-07-01',
    info: ''
  },
  {
    id: 2,
    exchange: 'images/binance.png',
    nickname: 'test',
    uniqueName: '1234567',
    follow_type: '定比跟单',
    status: 0,
    create_datetime: '2023-07-01',
    info: '失败'
  },
  {
    id: 3,
    exchange: 'images/binance.png',
    nickname: 'test',
    uniqueName: '1234567',
    follow_type: '定比跟单',
    status: 0,
    create_datetime: '2023-07-01',
    info: '成功'
  }
]


function transformItem(item) {
  let imagePath;
  switch(item.exchange) {
    case 1:
      imagePath = 'images/okx.png';
      break;
    case 2:
      imagePath = 'images/binance.png';
      break;
      // 可以添加更多的条件
    default:
      imagePath = 'images/face-3.jpeg'; // 默认图片
  }
  return {
    id: String(item.id), // 使用项目的 'id' 字段
    exchange: imagePath,
    uniqueName: item.uniqueName,
    follow_type: item.follow_type,
    nickname: item.nickname,
    status: item.status,
    info: item.info,
    create_datetime: item.created_at,
  };
}


export default {
  data() {
    return {
      tableData: [],
      columns,


    };
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem("token");
        const response = await http.get("/api/grabtask/?token=" + token);
        const originalData = response.data.data;
        const tableData = originalData.map(item => transformItem(item));
        this.tableData = tableData; // 更新组件的数据属性
        console.log(response.data.data); // 现在这里将正确打印出数据
      } catch (error) {
        console.error("请求失败:", error);
      }
    },

    // 终止任务
    stop(id) {
      console.log(id);
      this.$confirm({
          title: '确认终止',
          content: '您确定要终止抢跟单席位吗？',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            return new Promise((resolve, reject) => {
              http.patch("/api/grabtask/?token=" + localStorage.getItem("token"), {id: id})
                  .then(() => {
                    this.fetchData(); // 删除成功后调用 fetchData
                    resolve();
                  })
                  .catch(err => {
                    reject(err); // 错误处理
                  });
            });
          },
          onCancel() {
            console.log('删除操作取消');
          },
        });
      },

    addapi() {
      this.$router.push({path: '/grab-task'});
    }

  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style lang="scss">
.ant-table-pagination.ant-pagination {
  margin-right: 25px;
  margin-left: 25px;
}
</style>